import { useIntl } from '@swe/shared/tools/intl';
import { useContainerBPDiscreteWidth } from '@swe/shared/ui-kit/components/container/utils/use-extend-el-to-container';
import { Hero } from '@swe/shared/ui-kit/components/hero';

import { NoDiscountsIllustration } from '@swe/shared/ui-kit/components/illustration';

import Loader from '@swe/shared/ui-kit/components/loader';
import { useBreakpointValue } from '@swe/shared/ui-kit/components/media/utils';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import { CSSProperties } from 'react';

import styles from './styles.module.scss';

import { TextDisclaimer } from 'common/components/text-disclaimer';

import { DiscountCard } from 'domains/product/components/discount-card';
import { Banner } from 'entities/banner/banner';

export type BannersListProps = ComponentHasClassName & {
  viewMode?: 'grid' | 'list';
  maxNotLazyItems?: number;
  items: Banner[];
  loading: boolean;
  disclaimer?: string;
};

export const BannersList = ({
  className,
  viewMode = 'grid',
  maxNotLazyItems = 0,
  items,
  loading,
  disclaimer,
}: BannersListProps) => {
  const { intl } = useIntl();
  const width = useContainerBPDiscreteWidth();
  const itemsPerRow = useBreakpointValue({ xs: 1, sm: 2, lg: 3 }, 1);

  if (loading) {
    return <Loader centered />;
  }

  if (!loading && !items.length) {
    return (
      <Hero
        title={intl.t('domains.catalog.discounts.list.noEntries', undefined, 'No Discounts')}
        illustration={<NoDiscountsIllustration />}
        border={false}
      />
    );
  }

  return (
    <div className={cx(className, styles.root, viewMode === 'list' && styles._viewMode_list)}>
      <div
        className={styles.items}
        style={{ '--items-per-row': itemsPerRow } as CSSProperties}
      >
        {items.map((item, index) => (
          <DiscountCard
            key={item.id}
            className={styles.item}
            id={item.id}
            name={item.name}
            {...item.promo}
            link={item.link ?? (!item.promo ? null : undefined)}
            mobileBannerUrl={item.mobileImage}
            width={width / itemsPerRow}
            variant={viewMode === 'list' ? 'horizontal' : 'vertical'}
            lazy={index >= maxNotLazyItems}
          />
        ))}
      </div>
      {items.length > 0 && disclaimer && <TextDisclaimer text={disclaimer} />}
    </div>
  );
};

export default BannersList;
