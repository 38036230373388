import { useMemo } from 'react';

import { useStoreConfig } from 'common/providers/config';

import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { BannersList, BannersListProps } from 'domains/product/components/banners-list';
import { GetPromoBannerListEndpoint } from 'endpoints/promotion/get-promotion-list';
import { Banner } from 'entities/banner/banner';
import { Discount } from 'entities/discount/discount';

const mapPromoToBanner = (promo: Discount): Banner => ({
  id: promo.id,
  name: promo.name,
  image: promo.bannerUrl,
  mobileImage: promo.mobileBannerUrl,
  promo,
});

type DiscountListProps = Omit<BannersListProps, 'items' | 'loading'>;

export const DiscountList = (props: DiscountListProps) => {
  const { usageDisclaimer } = useStoreConfig();
  const { saleType } = useSaleType();
  const { platformOs } = usePlatformOs();
  const { data: items = [], isLoading } = GetPromoBannerListEndpoint.useRequest(
    { saleType, platformOs },
    { headers: { SaleType: saleType as string } },
  );
  const bannerItems = useMemo(
    () => items.filter((discount) => discount.displayInDiscountMenu !== false).map(mapPromoToBanner),
    [items],
  );
  return (
    <BannersList
      {...props}
      items={bannerItems}
      loading={isLoading}
      disclaimer={usageDisclaimer}
    />
  );
};

export default DiscountList;
